<template>
  <!-- 报名管理 -->
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        报名管理
      </template>
      <template #input>
        <a-input placeholder="请输入活动名称" v-model="queryParams.name" allowClear />
        <a-select placeholder="请选择活动类型" v-model="queryParams.activityType" showSearch allowClear>
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option :value="0">免费</a-select-option>
          <a-select-option :value="1">付费</a-select-option>
        </a-select>
        <a-select placeholder="请选择活动状态" v-model="queryParams.published" showSearch allowClear>
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option :value="1">上架</a-select-option>
          <a-select-option :value="0">下架</a-select-option>
        </a-select>
        <a-range-picker :placeholder="['活动开始时间', '活动结束时间']" valueFormat="YYYY-MM-DD HH:mm:ss" showTime @change="onSelectTime" v-model="times"></a-range-picker>
        <a-button type="primary" class="btn" :loading="loading" @click="onSearch()">搜索</a-button>
    </template>
    </HeaderBox>

    <a-table 
      class="table-template"
      :rowKey="item => item.id"
      :columns="columns" 
      :loading="loading"
      :data-source="tableData" 
      :locale="{emptyText: '暂无数据'}" 
      @change="onPage"
      :pagination="{
        total:total,
        current:queryParams.pageNum,  
        defaultpageSize:queryParams.pageSize, 
        showSizeChanger: true,
        showTotal: function(total, range){
          return `共${total}条`
        }
      }">
      <!-- 序号 -->
      <template slot="index" slot-scope="item, row, i">
        {{ (queryParams.pageNum - 1) * queryParams.pageSize + i + 1 }}
      </template>
      <!-- 活动类型 -->
      <template slot="activityType" slot-scope="item">
        {{ item == 1 ? '付费' : '免费'}}
      </template>
      <!-- 活动时间 -->
      <template slot="activeTime" slot-scope="item">
        {{ item.activityStartDate }} ~ {{ item.activityEndDate }}
      </template>
      <template slot="published" slot-scope="item">
        <a-tag :color="item == 1 ? 'green' : 'red'">{{ item == 1 ? '上架' : '下架'}}</a-tag>
      </template>
      <!-- 操作 -->
      <template slot="operation" slot-scope="item">
        <span class="blueText">
          <span @click="toDetail(item.id)">报名审核</span> | 
          <span @click="onSessionShow(item.id)">场次列表</span>
        </span>
      </template>
    </a-table>

    <!-- 场次列表 -->
    <a-modal v-model="isSessionShow" width="1300px" title="场次列表">
      <div>
        <a-table
          class="table-template"
          :rowKey="(item,index)=>index"
          :columns="sessionColumns" 
          :loading="loading"
          :data-source="sessionTableData" 
          :locale="{emptyText: '暂无数据'}" 
          :pagination="false">
          <!-- 序号 -->
        <template
          slot="index"
          slot-scope="item, row, index"
        >
          {{ index + 1 }}
        </template>
      </a-table>
      </div>
      <template slot="footer">
        <a-button type="primary" @click="isSessionShow = false">确认</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
const columns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "活动名称",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "活动类型",
    align: "center",
    dataIndex: "activityType",
    scopedSlots: { customRender: "activityType" },
  },
  {
    title: "活动时间",
    align: "center",
    scopedSlots: { customRender: "activeTime" },
  },
  {
    title: "举办城市",
    align: "center",
    dataIndex: "city",
  },
  {
    title: "活动状态",
    align: "center",
    dataIndex: "published",
    scopedSlots: { customRender: "published" },
  },
  {
    title: "创建时间",
    align: "center",
    dataIndex: "createTime",
  },
  {
    title: "操作",
    align: "center",
    width: 170,
    scopedSlots: {
      customRender: "operation",
    },
  },
];
const sessionColumns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "场次名称",
    align: "center",
    dataIndex: "sessionName",
  },
  {
    title: "城市",
    align: "center",
    dataIndex: "city",
  },
  {
    title: "活动地点",
    align: "center",
    dataIndex: "address",
  },
  {
    title: "报名人数限制",
    align: "center",
    dataIndex: "number",
  },
  {
    title: "已报名人数",
    align: "center",
    dataIndex: "signUpNumber",
  },
  {
    title: "报名开始时间",
    align: "center",
    dataIndex: "signupStartDate",
  },
  {
    title: "报名结束时间",
    align: "center",
    dataIndex: "signupEndDate",
  },
  {
    title: "活动开始时间",
    align: "center",
    dataIndex: "activityStartDate",
  },
  {
    title: "活动结束时间",
    align: "center",
    dataIndex: "activityEndDate",
  },
];
export default {
  // 可用组件的哈希表
  components: {HeaderBox},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      columns,
      sessionColumns,
      loading:false,
      tableData:[],
      sessionTableData:[],
      total:0,
      queryParams: {
        name: undefined, // 活动名称
        activityType: undefined, // 活动类型 0免费 1付费
        published: undefined, // 活动状态 0下架 1上架
        activityStartDate: undefined, // 活动开始时间
        activityEndDate: undefined, // 活动结束时间	
        pageNum: 1, //页码
        pageSize: 10, // 页数
      },
      times:[],
      isSessionShow:false,
    }
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e){
      this.queryParams.pageNum = e.current
      this.queryParams.pageSize = e.pageSize
      this.getList()
    },
    // 选择时间
    onSelectTime(date, dateString) {
      this.queryParams.activityStartDate = dateString[0]; // 开始日期
      this.queryParams.activityEndDate = dateString[1]; // 结束日期
    },
    onSearch() {
      this.queryParams.pageNum = 1;
      this.getList();
    },

    // 查询活动列表
    getList(e) {
      this.loading = true
      this.$ajax({
        url: "/hxclass-management/activity/manage/list",
        params: this.queryParams,
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.total = res.data.total;
          this.tableData = res.data.records;
        }
      });
    },

    // 页面跳转
    toDetail(activityId){
      this.$router.push({
        path: "/admin/Activity/SignupAuditList?activityId=" + activityId,
      });
    },
    onSessionShow(id){
      this.$ajax({
        url: "/hxclass-management/activitySession/list/signUp/activityId?id=" + id,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          console.log(res.data.records);
          this.sessionTableData = res.data;
          this.isSessionShow = true
        }
      });
    }
  },
  // 生命周期-实例创建完成后调用
  created () { 
    this.getList()
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.btn {
  margin: 0 24px 14px 0;
}
</style>
